import React, { useEffect, useRef, useState } from "react";
import styles from "./Prizes.module.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useStoreState } from "easy-peasy";

const Prizes = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const { lang, country } = useStoreState((store) => store.user);
  const DACH = ["AT", "CH"];

  const DEPrizes = [
    {
      title: t("DEPrize1Title"),
      subtitle: t("DEPrize1Subtitle"),
      image_de: "/images/BestPlayer_TV_DEonly_de.png",
      image_en: "/images/BestPlayer_TV_DEonly_de.png",
    },
    {
      title: t("DEPrize2Title"),
      subtitle: t("DEPrize2Subtitle"),
      image_de: "/images/DailyWinners_Mobile_DEonly_de.png",
      image_en: "/images/DailyWinners_Mobile_DEonly_de.png",
    },
    {
      title: t("DACHPrize1Title"),
      subtitle: t("DACHPrize1Subtitle"),
      image_de: "/images/BestTeam_Sportworld_de.png",
      image_en: "/images/BestTeam_Sportworld_de.png",
    },
  ];

  const DACHPrizes = [
    {
      title: t("DACHPrize1Title"),
      subtitle: t("DACHPrize1Subtitle"),
      image_de: "/images/BestTeam_Sportworld_de.png",
      image_en: "/images/BestTeam_Sportworld_de.png",
    }
  ];

  const WorldPrizes = [
    {
      title: t("WorldPrize1Title"),
      subtitle: t("WorldPrize1Subtitle"),
      image_de: "/images/BestPlayer_Sportworld_en.png",
      image_en: "/images/BestPlayer_Sportworld_en.png",
    },
    {
      title: t("WorldPrize2Title"),
      subtitle: t("WorldPrize2Subtitle"),
      image_de: "/images/BestTeam_Sportworld_en.png",
      image_en: "/images/BestTeam_Sportworld_en.png",
    },
  ];

  useEffect(() => {
    if (country === "DE") {
      setPrizes(DEPrizes);
    } else if (DACH.includes(country)) {
      setPrizes(DACHPrizes);
    } else {
      setPrizes(WorldPrizes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, country]);

  const [prizes, setPrizes] = useState(WorldPrizes);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={classNames(styles.prizes_container, "container")} ref={ref}>
      <div className={styles.filters}>
        <button
          className={classNames(styles.button, {
            [styles.active]: activeIndex === 0,
          })}
          onClick={() => {
            setActiveIndex(0);
          }}
        >
          {t("menu.prizes")}
        </button>
        <button
          className={classNames(styles.button, {
            [styles.active]: activeIndex === 2,
          })}
          onClick={() => {
            setActiveIndex(2);
          }}
        >
          {t("leaderboards.rules")}
        </button>
      </div>
      {activeIndex !== 2 ? (
        <div className={classNames(styles.prizes)}>
          {prizes.map((item, index) => {
            return (
              <div key={index} className={classNames(styles.prize_card)}>
                <div className={classNames(styles.prize_card__images)}>
                  <img src={lang === "de" ? item.image_de : item.image_en} alt={item.title} />
                </div>
                <div className={classNames(styles.prize_card__info)}>
                  <span className={classNames(styles.title)}>{item.title}</span>
                  <span className={classNames(styles.subtitle)}>{item.subtitle}</span>
                </div>
              </div>
            );
          })}
        </div>
      ) : lang === "de" ? (
        <div className={styles.rules}>
          <h2>SO MACHST DU MIT:</h2>
          <br />
          <br />

          <h3>SCHRITT 1: ÖFFNE DIE SPORTWORLD APP</h3>
          <p>
            Lade dir die Sportworld App auf dein Smartphone oder Tablet herunter und öffne sie. Rufe im Menü den Punkt „Tippspiel“ auf.
          </p>
          <br />
          <br />

          <h3>SCHRITT 2: REGISTRIERE DICH ODER LOGGE DICH EIN</h3>
          <p>
            Registriere dich kostenfrei auf <a href="https://sportworld.tv/">Sportworld.tv</a> und erstelle deinen Sportworld-Account. Folge den Anweisungen auf deinem Bildschirm. Hast du bereits einen Account, melde dich unter „Login“ an.
          </p>
          <br />
          <br />

          <h3>SCHRITT 3: WÄHLE DEINEN NICKNAMEN UND DEIN TEAM</h3>
          <p>
            Im Menüpunkt „Tippspiel“ in der mobilen Sportworld App wählst du einen Nicknamen und dein Team. Akzeptiere die Teilnahmebedingungen. Die Teilnahme am Tippspiel ist kostenlos.
          </p>
          <br />
          <br />

          <h3>SCHRITT 4: GIB TÄGLICH DEINEN TIPP AB</h3>
          <p>
            Besuche die Sportworld App täglich, um deine Tipps abzugeben oder zu ändern. Tippe auf den Sieger und das genaue Endergebnis jeder Partie. Das Tippen ist bis zum Anstoß des jeweiligen Spiels möglich.
          </p>
          <br />
          <br />

          <h3>SCHRITT 5: SAMMLE PUNKTE AUF BASIS VON ECHTEN WAHRSCHEINLICHKEITEN</h3>
          <p>
            Punkte werden basierend auf echten Wahrscheinlichkeiten vergeben. Tipps mit hoher Wahrscheinlichkeit bringen weniger Punkte, risikoreiche mehr. Dies sorgt für einen dynamischen und spannenden Wettbewerb.
          </p>
          <br />
          <br />

          <h3>SCHRITT 6: TIPPE AUF DEN CHAMPIONS LEAGUE-SIEGER</h3>
          <p>
            Der Tipp auf den Champions League-Sieger ist von Anfang an möglich und kann bis zum ersten K.o.-Spiel angepasst werden. Anfänglich bringt dieser Tipp viele Punkte, die sich je nach Leistung des Teams reduzieren können.
          </p>
          <br />
          <br />

          <h3>SCHRITT 7: ZUSÄTZLICHE FRAGEN AB DER K.O.-PHASE</h3>
          <p>
            Ab der K.o.-Phase kannst du zusätzliche Fragen beantworten, wie „Wer schießt ein Tor?“ oder „Wer gewinnt die erste Halbzeit?“, um extra Punkte zu sammeln und auch in den späteren Phasen noch erfolgreich zu sein.
          </p>
          <br />
          <br />

          <h3>SCHRITT 8: GEWINNE ATTRAKTIVE PREISE</h3>
          <p>
            Der Gesamtsieger und die jeweiligen Tagessieger haben die Chance auf attraktive Preise. Alle Details zu den Preisen findest du in der Rubrik „Preise“ in deiner Sportworld App.
          </p>
          <br />
          <br />
          <p>
            Viel Spaß und Erfolg beim Mitmachen! Hinweis: Apple und Google sind nicht an diesem Tippspiel beteiligt und stellen keinen Sponsor dar.
          </p>
          <br />
        </div>
      ) : (
        <div className={styles.rules}>
          <h2>HOW TO PARTICIPATE:</h2>
          <br />
          <br />

          <h3>STEP 1: OPEN THE SPORTWORLD APP</h3>
          <p>
            Download the Sportworld App on your smartphone or tablet and open it. Navigate to the "Prediction Game" section in the menu.
          </p>
          <br />
          <br />

          <h3>STEP 2: REGISTER OR LOG IN</h3>
          <p>
            Register for free at <a href="https://sportworld.tv/">Sportworld.tv</a> and create your Sportworld account. Follow the instructions on your screen. If you already have an account, log in under "Login".
          </p>
          <br />
          <br />

          <h3>STEP 3: CHOOSE YOUR NICKNAME AND TEAM</h3>
          <p>
            In the "Prediction Game" section of the Sportworld App, choose a nickname and your team. Accept the terms and conditions.
            <br />
            Participation in the prediction game is free.
          </p>
          <br />
          <br />

          <h3>STEP 4: MAKE YOUR DAILY PREDICTION</h3>
          <p>
            Visit the Sportworld App daily to make or update your predictions. Predict the winner and the exact score for each match. You can make predictions until the match starts.
          </p>
          <br />
          <br />

          <h3>STEP 5: EARN POINTS BASED ON REAL PROBABILITIES</h3>
          <p>
            Points are awarded based on real probabilities. Predictions with high probability earn fewer points, while riskier predictions earn more, keeping the competition exciting and dynamic.
          </p>
          <br />
          <br />

          <h3>STEP 6: PREDICT THE CHAMPIONS LEAGUE WINNER</h3>
          <p>
            Predict the Champions League Winner from the beginning and adjust your prediction until the first knock out match. Initially, this prediction can earn many points, which may decrease depending on the team's performance.
          </p>
          <br />
          <br />

          <h3>STEP 7: ADDITIONAL QUESTIONS FROM KNOCK OUT ROUNDS</h3>
          <p>
            From the knock out round onwards, you can answer additional questions like "Who will score a goal?" or "Who will win the first half?" to earn extra points and stay competitive in the later stages.
          </p>
          <br />
          <br />

          <h3>STEP 8: WIN ATTRACTIVE PRIZES</h3>
          <p>
            Both the overall winner and the daily winners have the chance to win attractive prizes. For details about the prizes, check the "Prizes" section in your Sportworld App.
          </p>
          <br />
          <br />
          <p>
            Have fun and good luck! Note: Apple and Google are not involved in this prediction game and are not a sponsor.
          </p>
          <br />
        </div>
      )}
    </div>
  );
};

export default Prizes;
