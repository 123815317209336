import React, { useState, useEffect, useRef } from "react";
import "./TeamsPopup.scss";
import closeIcon from "../../assets/icons/close-icon.svg";
import { useTranslation } from "react-i18next";

const TeamsPopup = ({ setSelectedTeam, setOpenInfoPopUp, title, data, setPlayersPopUpIsOpen }) => {
  const { t } = useTranslation();
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);

  const listenForOutsideClicks = (listening, setListening, menuRef) => {
    return () => {
      [`click`, `touchstart`].forEach(() => {
        document.addEventListener(`click`, (evt) => {
          if (!listening) {
            setListening(true);
            return;
          } else {
            const cur = menuRef?.current;
            const node = evt?.target;

            if (cur) {
              if (!cur.contains(node)) {
                setOpenInfoPopUp(false);
              }
            }
          }
        });
      });
    };
  };

  useEffect(
    () => {
      listenForOutsideClicks(listening, setListening, menuRef);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleTeam = (team) => {
    setSelectedTeam(team);
    setOpenInfoPopUp(false);
    if (setPlayersPopUpIsOpen) {
      setPlayersPopUpIsOpen(true);
    }
  };

  return (
    <div className="pop-up-info-container">
      <div className="pop-up-container--inner" ref={menuRef}>
        <div className="btn-close--container">
          <button
            className="btn-close"
            onClick={() => {
              setOpenInfoPopUp(false);
            }}
          >
            <img src={closeIcon} alt="close" />
          </button>
        </div>

        <h2 className="terms-title">{title}</h2>
        <div className="teams_container">
          {data.map((item, index) => {
            return (
              <div className="team_container" onClick={() => handleTeam(item)} key={index}>
                {/* <div className="team_logo">
                  <img src={item.emblemUrl} alt={item.name} />
                </div> */}
                <div className="team_title">
                  <p>{t(item.name)}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TeamsPopup;
